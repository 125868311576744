<template>
  <DefaultTemplate>
    <div class="depositFunds-box">
      <div class="form-left">
        <div class="content">
          <div class="info_box">
            <p>{{ langTranslate('deposit.default.channels.', paymentDetails.merchant_variable.description_title) }}</p>
            <DepositInfo :instData="instData" :noteData="noteData" :showNote="showNote"></DepositInfo>
          </div>
          <div class="form_main">
            <p class="title">
              {{
                $t('deposit.cps', {
                  name: paymentDetails.merchant_variable.label
                    ? langTranslate('deposit.default.channels.', paymentDetails.merchant_variable.label)
                    : langTranslate('deposit.default.channels.', paymentDetails.merchant_variable.description_title)
                })
              }}
            </p>
            <el-form label-position="top" :model="cpsForm" ref="cpsForm" status-icon :rules="cpsRules">
              <div class="form-box">
                <ul class="clearfix">
                  <li>
                    <AccountNumber
                      :supportedCurrenciesList="tradeAccountCurrencies"
                      @setCurrency="setCurrency"
                      @setAccountNumber="setAccountNumber"
                    ></AccountNumber>
                  </li>
                  <li>
                    <el-form-item :label="setAmtLabel(accountCurrency, cpsForm.amount)" prop="amount">
                      <numeric-input
                        v-model="cpsForm.amount"
                        :currency="accountCurrency"
                        :precision="2"
                      ></numeric-input>
                    </el-form-item>
                  </li>
                </ul>

                <!-- Attach Variables -->
                <ul class="clearfix" v-if="cpsForm.attachVariables.length > 0">
                  <li v-for="(type, index) in cpsForm.attachVariables" :key="type.key">
                    <!-- Input Field -->
                    <DynamicRestrictInput
                      :label="getFieldLabel(type.field_name)"
                      :name="'attachVariables.' + index + '.value'"
                      :testId="type.key"
                      :restrictionType="type.restriction_type"
                      v-model="cpsForm.attachVariables[index].value"
                      :maxlength="type.field_length"
                      v-if="type.field_type == 'input_field'"
                    ></DynamicRestrictInput>

                    <!-- Dropdown List -->
                    <el-form-item
                      v-if="type.field_type == 'dropdown_list' && type.field_name != 'depositor'"
                      :label="$t('common.field.bankName')"
                      :prop="'attachVariables.' + index + '.value'"
                      :rules="selectRules"
                    >
                      <el-select
                        v-model="cpsForm.attachVariables[index].value"
                        :placeholder="$t('common.field.select')"
                        :data-testid="type.key"
                      >
                        <el-option
                          :label="value.bank_name"
                          :value="value.bank_code"
                          v-for="(value, name) in type.field_content"
                          :key="name"
                        ></el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item
                      v-if="type.field_type == 'dropdown_list' && type.field_name == 'depositor'"
                      :label="$t('common.field.bankName')"
                      :prop="'attachVariables.' + index + '.value'"
                      :rules="selectRules"
                    >
                      <el-select
                        v-model="cpsForm.attachVariables[index].value"
                        :placeholder="$t('common.field.select')"
                        :data-testid="type.key"
                      >
                        <el-option
                          :label="value.desc"
                          :value="value.value"
                          v-for="(value, name) in type.field_content"
                          :key="name"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </li>
                </ul>

                <ul class="clearfix">
                  <li class="data" v-if="showExchangeRates">
                    <p>
                      {{
                        $t('deposit.default.rate.rate', {
                          oldCurrency: accountCurrency,
                          newCurrency: newCurrencyRate
                        })
                      }}
                      <span>{{ rate }}</span>
                    </p>
                    <p>
                      {{
                        accountCurrency === 'USC' && this.paymentDetails.unique_currency
                          ? 'USD'
                          : paymentDetails.actual_currency_code
                      }}: <span>{{ rateChange }}</span>
                    </p>
                  </li>
                  <li>
                    <el-form-item :label="$t('common.field.imptNotes')">
                      <el-input v-model="cpsForm.notes" data-testid="imptNotes"></el-input>
                    </el-form-item>
                  </li>
                </ul>
              </div>
              <el-form-item>
                <el-button
                  class="btn-blue"
                  :loading="loading"
                  :disabled="loading"
                  @click="submitCpsForm()"
                  data-testid="submit"
                >
                  {{ $t('common.button.submit') }}
                </el-button>
              </el-form-item>
            </el-form>
            <div v-html="bankHTML"></div>
          </div>
        </div>
      </div>
      <div class="form-right">
        <div
          class="logo"
          :class="paymentLogo(paymentDetails.payment_method, paymentDetails.actual_currency_code)"
        ></div>
      </div>
    </div>
  </DefaultTemplate>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import AccountNumber from '@/components/form/AccountNumber';
import DefaultTemplate from '@/components/template/deposit/DefaultTemplate';
import mixin from '@/mixins';
import cpsMixin from '@/mixins/page/deposit/cpsDeposit';
import DepositInfo from '@/components/payment/DepositInfo.vue';
import DynamicRestrictInput from '@/components/form/DynamicRestrictInput';
import fieldLabelList from '@/constants/depositFieldLabel';
import { paymentSetting } from '@/constants/payment/depositSetting.json';
import { apiCpsCreditCardpayment } from '@/resource/cps';

export default {
  name: 'CreditCard',
  components: { NumericInput, AccountNumber, DefaultTemplate, DepositInfo, DynamicRestrictInput },
  mixins: [mixin, cpsMixin],
  data() {
    return {
      rate: 0.0,
      redirectType: 1,
      accountCurrency: '',
      exchangeRates: false,
      showExchangeRates: false,
      showNote: true
    };
  },
  computed: {
    instData() {
      return this.getDepositInfo('instData');
    },
    noteData() {
      return this.getDepositInfo('noteData');
    },
    descriptionTitle() {
      return this.langTranslate('deposit.default.channels.', this.paymentDetails.merchant_variable.description_title);
    }
  },
  watch: {
    accountCurrency() {
      this.determineExchangeRate();
      this.updateLimit();
    }
  },
  mounted() {
    this.cpsForm.attachVariables = this.paymentDetails.attach_variable
      ? this.paymentDetails.attach_variable.filter(f => f.hasOwnProperty('field_type'))
      : '';
    this.cpsForm.mandatory = this.paymentDetails.attach_variable
      ? this.paymentDetails.attach_variable.filter(f => f.length > 0)?.[0]
      : '';
  },
  methods: {
    getFieldLabel(val) {
      const i18nKey = this.getI18nKey(val);

      if (this.$te(i18nKey) || this.$te(i18nKey, 'en_US')) {
        if (i18nKey === 'common.field.paymentAccName' || i18nKey === 'common.field.paymentAccNum')
          return this.$t(i18nKey, { name: val.split(' ')[0] });
        return this.$t(i18nKey);
      }

      return val;
    },
    getI18nKey(val) {
      let label = fieldLabelList[val];
      return label ? label : val;
    },
    getDepositInfo(type) {
      if (!paymentSetting[this.paymentDetails.payment_method]) return;
      let paymentCopywriting = paymentSetting[this.paymentDetails.payment_method].copywriting[type];

      return paymentCopywriting.map(el => {
        let paramsObj = {};
        if (el.obj) paramsObj = el.obj;
        if (el.parameter) {
          Object.entries(el.parameter).forEach(([key, value]) => {
            function getParameter(params, parentValue) {
              parentValue = parentValue[params[0]];
              params.splice(0, 1);
              if (params.length !== 0) parentValue = getParameter(params, parentValue);
              return parentValue;
            }
            paramsObj[key] = getParameter(value.split('.'), this);
          });
        }
        return this.$t(el.value, paramsObj);
      });
    },
    submitDeposit() {
      let attachVariables = {};
      if (this.cpsForm.attachVariables) {
        this.cpsForm.attachVariables.map(a => {
          attachVariables[a.key] = a.value;
        });
      }

      const rawData = this.paymentDetails.attach_variable
        .filter(f => f.field_type)
        .reduce((acc, el) => ({ ...acc, [el.key]: el.value }), {});

      let requestBody = {
        mtAccount: this.cpsForm.accountNumber,
        operateAmount: this.cpsForm.amount,
        importantNotes: this.cpsForm.notes,
        currency: this.paymentDetails.actual_currency_code,
        attachVariables: JSON.stringify(attachVariables),
        paymentMethod: this.paymentDetails.payment_method,
        raw: JSON.stringify(rawData)
      };
      return apiCpsCreditCardpayment(requestBody, this.token);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/deposit.scss';
</style>
